<script setup>
useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - AVRillo Client Dashboard` : 'AVRillo Client Dashboard'
  },
})
</script>

<template>
  <Suspense>
    <nuxt-layout>
      <nuxt-page />
    </nuxt-layout>
  </Suspense>
</template>
