import { defineStore } from 'pinia'
import axiosService from '~/services/axios.service'
import { useConveyancingCaseStore } from './conveyancing-case'

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    access_token: null,
    token_type: 'Bearer',
    expires_in: null,
    client: null,
  }),
  getters: {
    authorizationHeader() {
      return `${this.token_type} ${this.access_token}`
    },
    isAuthenticated() {
      return !!this.access_token
    },
  },
  actions: {
    async login(formData) {
      return axiosService
        .post('/login', formData)
        .then((response) => {
          this.access_token = response.access_token
          this.token_type = response.token_type
          this.expires_in = response.expires_in
        })
        .catch((err) => {
          throw err
        })
    },
    async fetchClient() {
      return axiosService
        .get(`/client`)
        .then((response) => {
          this.client = response

          useConveyancingCaseStore().conveyancingCase = response.conveyancing_case
        })
        .catch((err) => {
          throw err
        })
    },
    async logout() {
      return await axiosService
        .post(`/logout`)
        .then(() => {
          this.access_token = null
          this.token_type = 'Bearer'
          this.expires_in = null
          this.client = null

          navigateTo('/login')
        })
        .catch((err) => {
          this.access_token = null
          this.token_type = 'Bearer'
          this.expires_in = null
          this.client = null

          navigateTo('/login')
        })
    },
  },
  persist: true,
})
