import * as Sentry from '@sentry/vue'
import { isEmpty } from 'lodash'

async function lazyLoadSentryIntegrations() {
  // don't load on server
  if (!process.client) return

  const { Replay } = await import('@sentry/vue')
  Sentry.addIntegration(
    new Replay({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: ['https://client-api.avrillo.co.uk/'],
    }),
  )
}

function getSentryIntegrations() {
  // don't load on server
  if (!process.client) return []

  const router = useRouter()

  return [Sentry.browserTracingIntegration({ router }), Sentry.captureConsoleIntegration()]
}

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  async setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp

    const {
      public: { sentry, environment },
    } = useRuntimeConfig()

    if (isEmpty(sentry.dsn)) {
      return
    }

    const messagesToIntercept = [
      '****************************************************************************************************************************',
      '************************************************ AG Grid Enterprise License ************************************************',
      '************************************************** License Key Not Found ***************************************************',
      '* All AG Grid Enterprise features are unlocked for trial.                                                                  *',
      '* If you want to hide the watermark please email info@ag-grid.com for a trial license key.                                 *',
      'Please see https://www.ag-grid.com/javascript-grid/modules/ for more information.',
      'AG Grid:',
    ]

    Sentry.init({
      app: vueApp,
      dsn: sentry.dsn,
      environment,
      integrations: getSentryIntegrations(),

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: sentry.trace_rate,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

      // This sets the sample rate. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: sentry.replay_rate,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: sentry.replay_error_rate,
      beforeSend(event, hint) {
        if (hint.originalException && hint.originalException?.status === 401) {
          return null
        }

        if (event.message && messagesToIntercept.some((msg) => event.message?.includes(msg))) {
          return null
        }

        return event
      },
    })

    // Lazy-load the replay integration to reduce bundle size
    lazyLoadSentryIntegrations()

    return {
      provide: { Sentry },
    }
  },
})
