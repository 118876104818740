import { default as case_45historyp7IwnRYGQqMeta } from "/opt/buildhome/repo/pages/case-history.vue?macro=true";
import { default as _91id_93mTSu5QQ59HMeta } from "/opt/buildhome/repo/pages/documents/[id].vue?macro=true";
import { default as indexPLPY3FW6dqMeta } from "/opt/buildhome/repo/pages/documents/[matter_type]/[group]/index.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as paymentt9BiBTWFpLMeta } from "/opt/buildhome/repo/pages/payment.vue?macro=true";
export default [
  {
    name: "case-history",
    path: "/case-history",
    meta: case_45historyp7IwnRYGQqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/case-history.vue").then(m => m.default || m)
  },
  {
    name: "documents-id",
    path: "/documents/:id()",
    meta: _91id_93mTSu5QQ59HMeta || {},
    component: () => import("/opt/buildhome/repo/pages/documents/[id].vue").then(m => m.default || m)
  },
  {
    name: "documents-matter_type-group",
    path: "/documents/:matter_type()/:group()",
    meta: indexPLPY3FW6dqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/documents/[matter_type]/[group]/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login4dfr6kRqBoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "payment",
    path: "/payment",
    meta: paymentt9BiBTWFpLMeta || {},
    component: () => import("/opt/buildhome/repo/pages/payment.vue").then(m => m.default || m)
  }
]