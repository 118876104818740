import { useAuthStore } from '@/store/auth'
import axios from 'axios'
import merge from 'lodash/merge'

const service = axios.create({
  baseURL: import.meta.env?.VITE_API_BASE || 'http://localhost/api',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

service.interceptors.request.use(
  (config) =>
    merge({}, config, {
      headers: {
        Authorization: useAuthStore().isAuthenticated ? useAuthStore().authorizationHeader : undefined,
      },
    }),
  (error) => Promise.reject(error),
)

service.interceptors.response.use(
  (response) => (response.data?.meta ? response.data : response?.data?.data ?? response.data),
  (error) => Promise.reject(error),
)

export default service
